export const CampaignChannel = Object.freeze({
  DISPLAY_SEARCH: { label: 'Display/Search', value: 'displaySearch' },
  OFFLINE: { label: 'Offline', value: 'offline' },
  REVIEW: { label: 'Review', value: 'reviews' },
  CRM: { label: 'CRM', value: 'crm' },
  SOCIAL_MEDIA: { label: 'Social Media', value: 'socialMedia' },
});

export const campaignChannelsList = [...Object.values(CampaignChannel)];

export const campaignChannelNames = campaignChannelsList.map(
  ({ label }) => label
);

export const campaignChannelValues = campaignChannelsList.map(
  ({ value }) => value
);
