import { colors } from '@/../themeConfig';

export const barChartColors = [
  colors.dark,
  colors.danger,
  colors.warning,
  colors.purple,
];

export const barChartOptions = {
  grid: {
    show: false,
    padding: {
      left: 0,
      right: 0,
    },
  },
  chart: {
    type: 'bar',
    toolbar: { show: false },
  },
  legend: {
    show: false,
  },
  yaxis: {
    tickAmount: 10,
  },
  colors: barChartColors,
  plotOptions: {
    bar: {
      distributed: true,
    },
  },
  tooltip: {
    x: { show: false },
  },
};

export const pieChartColors = [
  colors.dark,
  colors.danger,
  colors.warning,
  colors.purple,
  colors.primary,
];
