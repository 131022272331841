<template>
  <vx-card>
    <div
      class="vx-row flex-col-reverse md:flex-col-reverse sm:flex-row lg:flex-row"
    >
      <div class="vx-col w-full flex flex-col">
        <h4 class="text-center mb-8">Display ROI</h4>

        <vue-apex-charts
          v-if="data.series"
          :ref="id"
          type="bar"
          height="200"
          :options="options"
          :series="data.series"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

import { barChartOptions } from '@/constants';

export default {
  components: {
    VueApexCharts,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    seriesData: {
      type: Array,
      required: true,
    },
  },
  data() {
    const [val1, val2] = this.seriesData;

    const data = {
      options: {
        ...barChartOptions,
        chart: {
          id: this.id,
        },
        xaxis: {
          categories: [
            ['Planned ROI', `${val1}%`],
            ['ROI', `${val2}%`],
          ],
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return `${value}%`;
            },
          },
        },
      },
    };

    return data;
  },
  watch: {
    seriesData(data) {
      this.$refs[this.id].updateSeries([
        {
          data,
        },
      ]);

      this.$refs[this.id].updateOptions({
        xaxis: {
          categories: [
            ['Planned ROI', `${data[0]}%`],
            ['ROI', `${data[1]}%`],
          ],
        },
      });
    },
  },
};
</script>
