<template>
  <div id="dashboard-analytics">
    <h2 class="mb-8">Campaigns</h2>

    <vx-card ref="filterCard" title="Filters" class="mt-6 mb-8" collapseAction>
      <div class="vx-row flex mt-4">
        <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
          <vs-label text="Region" />
          <v-select
            v-model="filters.region"
            :clearable="false"
            :options="filterRegionList"
            class="bg-white w-full cursor-pointer"
            @input="filter"
          />
        </div>

        <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
          <vs-label text="Country" />
          <v-select
            v-model="filters.country"
            :clearable="false"
            :options="filterCountryList"
            class="bg-white w-full cursor-pointer"
            @input="filter"
          />
        </div>

        <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
          <vs-label text="Status" />
          <v-select
            v-model="filters.status"
            :clearable="false"
            :options="filterStatusList"
            class="bg-white w-full cursor-pointer"
            @input="filter"
          />
        </div>

        <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
          <vs-label text="Start date" />
          <div class="relative">
            <datepicker
              placeholder="Select Date"
              class="w-full"
              v-model="filters.startDate"
              @input="filter"
            />
            <feather-icon
              icon="XIcon"
              svg-classes="w-5 h-5 hover:text-danger stroke-current"
              class="datepicker-reset"
              @click="resetDate('startDate')"
            />
          </div>
        </div>

        <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
          <vs-label text="End date" />
          <div class="relative">
            <datepicker
              placeholder="Select Date"
              class="w-full"
              v-model="filters.endDate"
              @input="filter"
            />
            <feather-icon
              icon="XIcon"
              svg-classes="w-5 h-5 hover:text-danger stroke-current"
              class="datepicker-reset"
              @click="resetDate('endDate')"
            />
          </div>
        </div>

        <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
          <vs-label text="Retailer" />
          <v-select
            v-model="filters.retailer_id"
            :clearable="false"
            :options="filterRetailerList"
            class="bg-white w-full cursor-pointer"
            @input="filter"
          />
        </div>
      </div>
    </vx-card>

    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <chart-display-search
          v-if="chartsData.displaySearch.series[0].data"
          id="displayAndSearchChart"
          class="h-full"
          :chart-data="chartsData.displaySearch"
          :series-data="chartsData.displaySearch.series[0].data"
        />
      </div>

      <div class="vx-col w-full md:w-1/2 md:mt-0 mt-8">
        <chart-display-roi
          v-if="chartsData.displayRoi.series[0].data"
          id="displayRoiChart"
          class="h-full"
          :data="chartsData.displayRoi"
          :series-data="chartsData.displayRoi.series[0].data"
        />
      </div>
    </div>

    <div class="vx-row mt-8">
      <div class="vx-col w-full lg:w-1/3 md:w-1/2">
        <pie-chart-wrapper
          v-if="chartsData.roiPerChannel.data"
          :chart-data="chartsData.roiPerChannel.data"
          id="roiPerChannelChart"
          class="h-full"
          title="ROI per channel"
          is-percent
        />
      </div>

      <div class="vx-col w-full lg:w-1/3 md:w-1/2 md:mt-0 mt-8">
        <pie-chart-wrapper
          v-if="chartsData.totalRevenuePerChannel.data"
          :chart-data="chartsData.totalRevenuePerChannel.data"
          id="totalRevenuePerChannelChart"
          class="h-full"
          title="Total Revenue per channel"
          is-money
        />
      </div>

      <div class="vx-col w-full lg:w-1/3 lg:mt-0 md:w-1/2 mt-8">
        <pie-chart-wrapper
          v-if="chartsData.totalSoldPerActivity.data"
          :chart-data="chartsData.totalSoldPerActivity.data"
          id="totalSoldPerActivityChart"
          class="h-full"
          title="Total Sold per channel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select';

import ChartDisplaySearch from '@/views/ui-elements/ChartDisplaySearch';
import ChartDisplayRoi from '@/views/ui-elements/ChartDisplayRoi';
import PieChartWrapper from '@/views/ui-elements/charts/PieChartWrapper';

import { DashboardAction, RetailerAction } from '@/store/actionTypes';
import moduleRetailer from '@/store/retailers/moduleRetailer';

import { regionList } from '@/enums/Region';
import { countryList } from '@/enums/Country';
import { campaignStatusList } from '@/enums/CampaignStatus';
import { campaignChannelValues } from '@/enums/CampaignChannel';

export default {
  data() {
    return {
      chartsData: {
        displaySearch: {
          series: [
            {
              name: 'Value',
              data: null,
            },
          ],
        },
        displayRoi: {
          series: [
            {
              name: 'Value',
              data: null,
            },
          ],
        },
        roiPerChannel: {
          data: null,
        },
        totalRevenuePerChannel: {
          data: null,
        },
        totalSoldPerActivity: {
          data: null,
        },
      },

      // Filters
      filters: {
        region: { label: 'All', value: null },
        country: { label: 'All', value: null },
        status: { label: 'All', value: null },
        startDate: null,
        endDate: null,
        retailer_id: { label: 'All', value: null },
      },
    };
  },
  components: {
    Datepicker,
    vSelect,
    ChartDisplaySearch,
    ChartDisplayRoi,
    PieChartWrapper,
  },
  computed: {
    ...mapState('retailer', {
      retailers: ({ retailers }) => retailers,
    }),

    retailerList() {
      return this.retailers.map(({ id, name }) => ({ value: id, label: name }));
    },
    filterRegionList() {
      return [{ label: 'All', value: null }, ...regionList];
    },
    filterCountryList() {
      return [{ label: 'All', value: null }, ...countryList];
    },
    filterStatusList() {
      return [{ label: 'All', value: null }, ...campaignStatusList];
    },
    filterRetailerList() {
      return [{ label: 'All', value: null }, ...this.retailerList];
    },
  },
  methods: {
    ...mapActions('dashboard', [DashboardAction.fetch]),
    ...mapActions('retailer', [RetailerAction.fetch]),

    registerStoreModules() {
      if (!moduleRetailer.isRegistered) {
        this.$store.registerModule('retailer', moduleRetailer);
        moduleRetailer.isRegistered = true;
      }
    },
    resetDate(date) {
      this.filters[date] = null;
      this.filter();
    },
    createFilterQuery() {
      const filters = {
        region: this.filters.region.value,
        country: this.filters.country.value,
        status: this.filters.status.value,
        start_date: this.filters.startDate
          ? this.filters.startDate.getTime()
          : this.filters.startDate,
        end_date: this.filters.endDate
          ? this.filters.endDate.getTime()
          : this.filters.endDate,
        retailer_id: this.filters.retailer_id.value,
      };

      return filters;
    },
    async fetchData() {
      this.$vs.loading();

      try {
        const statistics = await this.fetchStatistics(this.createFilterQuery());
        const {
          planned,
          result,
          roiPerChannel,
          totalRevenuePerChannel,
          totalSoldPerActivity,
        } = statistics;

        const displaySearchData = [
          planned.units_budget,
          result.units_sold,
          planned.budget_revenue,
          result.revenue,
        ];

        const displayRoiData = [planned.roi, result.roi];

        const roiPerChannelData = campaignChannelValues.map(
          (channel) => roiPerChannel[channel]
        );

        const totalRevenuePerChannelData = campaignChannelValues.map(
          (channel) => totalRevenuePerChannel[channel]
        );

        const totalSoldPerActivityData = campaignChannelValues.map(
          (channel) => totalSoldPerActivity[channel]
        );

        this.chartsData.displaySearch.series[0].data = displaySearchData;
        this.chartsData.displayRoi.series[0].data = displayRoiData;
        this.chartsData.roiPerChannel.data = roiPerChannelData;
        this.chartsData.totalRevenuePerChannel.data = totalRevenuePerChannelData;
        this.chartsData.totalSoldPerActivity.data = totalSoldPerActivityData;
      } catch (error) {
        this.notifyError(error, 'Problem with loading statistics.');
      }

      this.$vs.loading.close();
    },
    filter() {
      this.fetchData();
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      await this.fetchRetailers();
      await this.fetchData();
    } catch (error) {
      this.notifyError(error, 'Problem with loading data.');
    }

    this.$vs.loading.close();
  },
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;

    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }

  .datepicker-reset {
    position: absolute !important;
    top: 10px;
    right: 4px;
    cursor: pointer;
    z-index: 2;
  }
}
</style>
