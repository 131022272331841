<template>
  <vx-card>
    <div
      class="vx-row flex-col-reverse md:flex-col-reverse sm:flex-row lg:flex-row"
    >
      <div class="vx-col w-full flex flex-col">
        <h4 class="text-center mb-8">Display/Search: Units &amp; Revenue</h4>
        <vue-apex-charts
          v-if="chartData.series"
          :ref="id"
          type="bar"
          height="200"
          :options="options"
          :series="chartData.series"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

import { barChartColors, barChartOptions } from '@/constants';

export default {
  components: {
    VueApexCharts,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    seriesData: {
      type: Array,
      required: true,
    },
  },
  data() {
    const [val1, val2, val3, val4] = this.seriesData;

    const data = {
      options: {
        ...barChartOptions,
        chart: {
          id: this.id,
        },
        xaxis: {
          categories: [
            ['Units budget', val1],
            ['Units sold', val2],
            ['Budget revenue', `${val3}$`],
            ['Revenue', `${val4}$`],
          ],
          labels: {
            style: {
              colors: barChartColors,
            },
          },
        },
      },
    };

    return data;
  },
  watch: {
    seriesData(data) {
      this.$refs[this.id].updateSeries([
        {
          data,
        },
      ]);

      this.$refs[this.id].updateOptions({
        xaxis: {
          categories: [
            ['Units budget', data[0]],
            ['Units sold', data[1]],
            ['Budget revenue', `${data[2]}$`],
            ['Revenue', `${data[3]}$`],
          ],
        },
      });
    },
  },
};
</script>
