<template>
  <vx-card>
    <div
      class="vx-row flex-col-reverse md:flex-col-reverse sm:flex-row lg:flex-row"
    >
      <div class="vx-col w-full flex flex-col">
        <h4 class="text-center mb-8">{{ title }}</h4>

        <pie-chart
          v-if="dataCollection"
          :height="250"
          :chart-data="dataCollection"
          :options="options"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import { Pie } from 'vue-chartjs';

import PieChart from '@/views/ui-elements/charts/PieChart';

import { campaignChannelNames } from '@/enums/CampaignChannel';

import { pieChartColors } from '@/constants';

export default {
  extends: Pie,
  components: {
    PieChart,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isMoney: {
      type: Boolean,
      default: false,
    },
    isPercent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const data = {
      options: {
        title: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const { index } = tooltipItem;
              const [dataset] = data.datasets;
              const value = dataset.data[index];

              if (this.isMoney) return `${value}$`;
              if (this.isPercent) return `${value}%`;
              return value;
            },
          },
        },
      },
    };

    return data;
  },
  computed: {
    dataCollection: {
      get() {
        return {
          labels: campaignChannelNames,
          datasets: [
            {
              backgroundColor: pieChartColors,
              data: this.chartData,
            },
          ],
        };
      },
      set(val) {
        this.chartData = val;
      },
    },
  },
  watch: {
    chartData(val) {
      this.dataCollection = val;
    },
  },
};
</script>
